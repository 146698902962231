import { IPriceInCard, TServiceCard } from '@/sharedLib';

export const SERVICES_DEFAULT_HEADER = 'Our services';

type TGetDefaultValuesProps = {
  services?: TServiceCard[];
  heading?: string;
};

export type TServicesFormFields = Required<TGetDefaultValuesProps>;

export const defaultPrice: IPriceInCard = {
  price: '',
  unit: 'for the service',
};

export const defaultService = {
  header: '',
  description: '',
  price: null,
  highlight: false,
} satisfies Required<TServiceCard>;

export const getDefaultValues = ({
  services = [],
  heading = SERVICES_DEFAULT_HEADER,
}: TGetDefaultValuesProps): TServicesFormFields => ({
  services: services.map((service) => ({
    ...defaultService,
    ...service,
    price: service.price,
  })),
  heading,
});
