import { Flex } from '@chakra-ui/react';
import { FC, ReactNode } from 'react';

interface LayoutPageProps {
  children: ReactNode;
  header?: ReactNode;
  footer?: ReactNode;
}

const LayoutPage: FC<LayoutPageProps> = ({ children, footer, header }) => {
  return (
    <Flex
      direction='column'
      minH='100vh'
    >
      {header}
      <Flex
        key='main'
        as='main'
        direction='column'
        flex={1}
      >
        {children}
      </Flex>
      {footer}
    </Flex>
  );
};

export default LayoutPage;
