import { TAccept } from '../constants';

export const parsAcceptedFileExtension = (acceptedFileExtension: TAccept[]) => {
  return Array.from(new Set(acceptedFileExtension)).join(', ');
};

export const convertBytesToMb = (bytes: number) => Math.round(bytes / 1024 / 1024);

export const getFileRequirements = (accept: TAccept[], maxFileSize: number) => {
  const acceptedExtensions = parsAcceptedFileExtension(accept);
  const maxFileSizeInMb = convertBytesToMb(maxFileSize);

  return `${acceptedExtensions}. Up to: ${maxFileSizeInMb} Mb`;
};
