import {
  BreadcrumbLink as ChakraBreadcrumbLink,
  LinkProps as ChakraLinkProps,
} from '@chakra-ui/react';
import NextLink from 'next/link';
import { FC } from 'react';

const BreadcrumbLink: FC<ChakraLinkProps & { href: string }> = ({ children, ...props }) => (
  <ChakraBreadcrumbLink
    as={NextLink}
    {...props}
    prefetch={false}
  >
    {children}
  </ChakraBreadcrumbLink>
);

export default BreadcrumbLink;
