import { CombinedError, useQuery } from 'urql';
import { configModel, coreConfigSelector } from '@/entities/Config';
import { useMutation } from '@/sharedAPI';
import { useToast } from '@/sharedUI';
import { UNKNOWN_ERROR_TEXT, parseGqlError } from '@/sharedLib';
import {
  CONFIRM_EMAIL,
  LANDING_PRIVATE_CONFIG,
  SEND_CONFIRM_EMAIL,
  SEND_CONTACT_FORM_MESSAGE,
  UPDATE_CONTACT_FORM_EMAIL,
} from '../gql';

const usePrivateData = () => {
  const [{ data, fetching: loading }] = useQuery({
    query: LANDING_PRIVATE_CONFIG,
  });

  return { privateData: data?.landingPrivateConfig, loading };
};

const useConfirmEmail = (
  callbackCompleted?: (status?: string) => void,
  callbackError?: () => void,
) => {
  const [confirmEmail, loading] = useMutation(CONFIRM_EMAIL, {
    onCompleted: (data: any) => {
      callbackCompleted?.(data?.confirmLandingContactFormEmail?.status);
    },
    onError: () => {
      callbackError?.();
    },
  });

  return {
    confirmEmail: (token: string) =>
      confirmEmail({
        input: {
          token,
        },
      }),
    loading,
  };
};

const useSendConfirmEmail = () => {
  const { showErrorToast, showSuccessToast } = useToast();
  const [sendConfirmEmail, loading] = useMutation(SEND_CONFIRM_EMAIL, {
    onCompleted: () => {
      showSuccessToast('Email sent successfully');
    },
    onError: () => {
      showErrorToast(UNKNOWN_ERROR_TEXT);
    },
  });

  return { confirmEmail: () => sendConfirmEmail({}), loading };
};

type TSendContactFormMessage = {
  email: string;
  phone: string;
  name: string;
  description: string;
};

const useSendContactFormMessage = (callback?: () => void) => {
  const { showErrorToast, showSuccessToast } = useToast();

  const { subdomain } = configModel.useGetStoreData(coreConfigSelector);

  const [sendMessage, loading] = useMutation(SEND_CONTACT_FORM_MESSAGE, {
    onCompleted: () => {
      showSuccessToast('Message sent successfully');
      callback?.();
    },
    onError: () => {
      showErrorToast(UNKNOWN_ERROR_TEXT);
    },
  });

  return {
    sendMessage: ({ email, phone, name, description }: TSendContactFormMessage) =>
      sendMessage({
        input: {
          subdomain,
          phone,
          name,
          description,
          email,
        },
      }),
    loading,
  };
};

const useUpdateContactFormEmail = (callback?: () => void) => {
  const { showErrorToast, showSuccessToast } = useToast();

  const [updateEmail, { loading }] = useMutation(UPDATE_CONTACT_FORM_EMAIL, {
    onCompleted: () => {
      showSuccessToast('Email updated successfully');
      callback?.();
    },
    onError: (error: CombinedError | undefined) => {
      const parseError = parseGqlError(error?.graphQLErrors);
      showErrorToast(parseError.message);
    },
  });

  return {
    updateEmail: (email: string, password: string) =>
      updateEmail({
        input: {
          email,
          password,
        },
      }),
    loading,
  };
};

const model = {
  usePrivateData,
  useSendConfirmEmail,
  useConfirmEmail,
  useUpdateContactFormEmail,
  useSendContactFormMessage,
};

export default model;
