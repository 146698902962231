import { Box, Divider, HStack, Text } from '@chakra-ui/react';
import { FC } from 'react';
import { TServiceCard } from '@/sharedLib';

const ServicesFullCard: FC<TServiceCard> = ({ header, description, price, highlight }) => {
  return (
    <>
      {highlight && (
        <Divider
          borderColor='primary.base'
          borderWidth='2px'
          mb='xs'
          mt='-xs'
          width='60%'
        />
      )}
      <Box
        flexDirection='row'
        fontWeight='semibold'
        h={8}
      >
        <HStack
          justifyContent='space-between'
          w='100%'
        >
          {price?.[0] && (
            <Box>
              <Text
                color='primary.base'
                display='inline'
                fontSize='md'
                mr='xs'
              >
                {price[0].price}
              </Text>
              <Text
                color='primary.medium'
                display='inline'
                fontSize='md'
              >
                {price[0].unit}
              </Text>
            </Box>
          )}
        </HStack>
      </Box>
      <Text
        fontSize='lg'
        textAlign='justify'
      >
        {description}
      </Text>
    </>
  );
};

export default ServicesFullCard;
