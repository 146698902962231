import { Heading } from '@chakra-ui/react';
import { FC } from 'react';
import { configModel, ourWorkersSelector } from '@/entities/Config';
import { CardsList, Layout } from '@/sharedUI';
import { IWorkerCard, TBlockProps } from '@/sharedLib';
import { OUR_WORKERS_DEFAULT_HEADER } from '../lib/getDefaultValues';
import WorkerCard from './WorkerCard';

const OurWorkers: FC<TBlockProps> = ({ isOdd, containerStyles }) => {
  const { heading, workers = [] } = configModel.useStore(ourWorkersSelector);

  return (
    <Layout.Container
      data-testid='workers'
      isOdd={isOdd}
      py='3xl'
      {...containerStyles}
    >
      <Heading
        mb='xl'
        textAlign='center'
        variant='homeBlock'
      >
        {heading || OUR_WORKERS_DEFAULT_HEADER}
      </Heading>
      <CardsList
        cards={workers.map((worker: IWorkerCard, index: number) => (
          <WorkerCard
            key={index}
            {...worker}
            isOdd={isOdd}
          />
        ))}
      />
    </Layout.Container>
  );
};

export default OurWorkers;
