import { FormLabel, FormLabelProps } from '@chakra-ui/react';
import { FC } from 'react';

interface ILabelForm extends FormLabelProps {
  label: string;
}

const LabelForm: FC<ILabelForm> = ({ label, ...rest }) => (
  <FormLabel
    color='primary.base'
    mb='3xs'
    {...rest}
  >
    {label}
  </FormLabel>
);

export default LabelForm;
