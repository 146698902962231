import { SVGAttributes } from 'react';

const PhoneIcon = (props: SVGAttributes<never>) => (
  <svg
    fill='none'
    height='100%'
    viewBox='0 0 20 20'
    xmlns='http://www.w3.org/2000/svg'
    {...props}
  >
    <path
      d='M5.20731 3.01272C5.1105 2.99336 4.99432 2.99335 4.91687 3.03208C3.87128 3.4387 3.21294 4.92964 3.05804 5.95587C2.57397 9.28627 5.20731 12.1713 7.62766 14.0689C9.77694 15.7534 13.9012 18.5223 16.3409 15.8503C16.6507 15.5211 17.0186 15.037 16.9993 14.553C16.9412 13.7397 16.186 13.1588 15.6051 12.7135C15.1598 12.3843 14.2304 11.4743 13.6495 11.4936C13.1267 11.513 12.7975 12.0551 12.4684 12.3843L11.8875 12.9652C11.7907 13.062 10.5514 12.2488 10.4159 12.152C9.91248 11.8228 9.4284 11.4549 9.00242 11.0483C8.57644 10.6417 8.18919 10.1963 7.86002 9.73161C7.7632 9.59607 6.96933 8.41495 7.04678 8.31813C7.04678 8.31813 7.72448 7.58234 7.91811 7.2919C8.32473 6.67229 8.63453 6.18822 8.16982 5.45243C7.99556 5.18135 7.78257 4.96836 7.55021 4.73601C7.14359 4.34875 6.73698 3.94213 6.27227 3.61296C6.02055 3.41933 5.59457 3.07081 5.20731 3.01272Z'
      fill='currentColor'
    />
  </svg>
);

export default PhoneIcon;
