import { SimpleGrid, StyleProps, VStack, useBreakpointValue } from '@chakra-ui/react';
import { FC, Fragment, ReactNode, useMemo } from 'react';
import { useShowAllButton } from '@/sharedUI';

interface IDotsSwiperProps {
  cards: ReactNode[];
  containerStyles?: StyleProps;
  defaultVisibleRows?: number;
  alwaysShowAll?: boolean;
}

const CardsList: FC<IDotsSwiperProps> = ({
  cards,
  containerStyles,
  defaultVisibleRows = 1,
  alwaysShowAll,
}) => {
  const showCardsCount =
    useBreakpointValue({
      base: 2,
      md: 2 * defaultVisibleRows,
      lg: 3 * defaultVisibleRows,
      xl: 4 * defaultVisibleRows,
    }) || 1;

  const { ShowAllButton, isShowAllCards } = useShowAllButton({
    itemsCount: cards?.length || 0,
    showCardsCount,
  });

  const { cardsToShow, columns } = useMemo(() => {
    const cardsToShow = isShowAllCards || alwaysShowAll ? cards : cards?.slice(0, showCardsCount);

    const cardsToShowCount = cardsToShow?.length || 0;

    const getMaxCountInRow = (count: number) =>
      cardsToShowCount >= count ? count : cardsToShowCount;

    return {
      cardsToShow,
      columns: {
        base: getMaxCountInRow(1),
        md: getMaxCountInRow(2),
        lg: getMaxCountInRow(3),
        xl: getMaxCountInRow(4),
      },
    };
  }, [cards, showCardsCount, isShowAllCards, alwaysShowAll]);

  return (
    <VStack {...containerStyles}>
      <SimpleGrid
        columns={columns}
        justifyItems='center'
        maxW='full'
        spacing={{ base: 'md', sm: 'lg' }}
        w='max'
      >
        {cardsToShow?.map((Card, index: number) => <Fragment key={index}>{Card}</Fragment>)}
      </SimpleGrid>
      {!alwaysShowAll && <ShowAllButton mt='xl' />}
    </VStack>
  );
};

export default CardsList;
