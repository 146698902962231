import { SVGAttributes } from 'react';

const InstagramIcon = (props: SVGAttributes<never>) => (
  <svg
    fill='none'
    height='100%'
    viewBox='-2 -2 24 24'
    xmlns='http://www.w3.org/2000/svg'
    {...props}
  >
    <g clipPath='url(#clip0_2152_2304)'>
      <path
        d='M9.99737 6.7456C8.20538 6.7456 6.74297 8.208 6.74297 9.99999C6.74297 11.792 8.20538 13.2544 9.99737 13.2544C11.7894 13.2544 13.2518 11.792 13.2518 9.99999C13.2518 8.208 11.7894 6.7456 9.99737 6.7456ZM19.7581 9.99999C19.7581 8.65234 19.7703 7.31689 19.6946 5.97167C19.619 4.40917 19.2625 3.02245 18.1199 1.87988C16.9749 0.734857 15.5906 0.380853 14.0281 0.30517C12.6805 0.229486 11.345 0.241693 9.99981 0.241693C8.65215 0.241693 7.3167 0.229486 5.97149 0.30517C4.40899 0.380853 3.02227 0.737298 1.87969 1.87988C0.734674 3.0249 0.38067 4.40917 0.304986 5.97167C0.229303 7.31933 0.24151 8.65478 0.24151 9.99999C0.24151 11.3452 0.229303 12.6831 0.304986 14.0283C0.38067 15.5908 0.737115 16.9775 1.87969 18.1201C3.02471 19.2651 4.40899 19.6191 5.97149 19.6948C7.31915 19.7705 8.65459 19.7583 9.99981 19.7583C11.3475 19.7583 12.6829 19.7705 14.0281 19.6948C15.5906 19.6191 16.9773 19.2627 18.1199 18.1201C19.2649 16.9751 19.619 15.5908 19.6946 14.0283C19.7728 12.6831 19.7581 11.3476 19.7581 9.99999V9.99999ZM9.99737 15.0073C7.22637 15.0073 4.99004 12.771 4.99004 9.99999C4.99004 7.229 7.22637 4.99267 9.99737 4.99267C12.7684 4.99267 15.0047 7.229 15.0047 9.99999C15.0047 12.771 12.7684 15.0073 9.99737 15.0073ZM15.2098 5.95702C14.5628 5.95702 14.0403 5.43456 14.0403 4.78759C14.0403 4.14062 14.5628 3.61816 15.2098 3.61816C15.8567 3.61816 16.3792 4.14062 16.3792 4.78759C16.3794 4.94122 16.3493 5.09337 16.2906 5.23534C16.2319 5.37731 16.1457 5.50631 16.0371 5.61494C15.9285 5.72357 15.7995 5.8097 15.6575 5.8684C15.5156 5.9271 15.3634 5.95722 15.2098 5.95702V5.95702Z'
        fill='currentColor'
      />
    </g>
    <defs>
      <clipPath id='clip0_2152_2304'>
        <rect
          fill='currentColor'
          height='19.5181'
          transform='translate(0.240784 0.240967)'
          width='19.5184'
        />
      </clipPath>
    </defs>
  </svg>
);

export default InstagramIcon;
