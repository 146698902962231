import themeBreakpoints from '@/app/theme/breakpoints';
import { ResponsiveValue } from '../../types';

const breakpointsKeys = ['base', ...Object.keys(themeBreakpoints)];
const breakpointsValues = [...Object.values(themeBreakpoints)];

const generateImageSizeMediaQueries = (data: ResponsiveValue | string, t = '') => {
  if (typeof data === 'string') return data;

  if (Array.isArray(data)) {
    if (data.length === 1) return data.join('');

    const sortedData = [...data.slice(1).reverse(), data[0]];
    return sortedData
      .map((value, index) => {
        const isLastItem = sortedData.length - 1 === index;
        if (isLastItem) return value;

        return value ? `(min-width: ${breakpointsValues[index]}) ${value}` : null;
      })
      .filter((v) => v)
      .join(', ');
  }

  return Object.entries(data)
    .sort((a, b) => breakpointsKeys.indexOf(b[0]) - breakpointsKeys.indexOf(a[0]))
    .map(([breakpoint, value], index, arr) => {
      const breakpointIndex = breakpointsKeys.indexOf(breakpoint);

      const breakpointIsNotExist = breakpointIndex < 0;
      if (breakpointIsNotExist) return null;

      const isOneBreakpoint = arr.length === 1;
      const isBaseBreakPoint = breakpoint === 'base';
      if (isOneBreakpoint || isBaseBreakPoint) return value;

      return `(min-width: ${breakpointsValues[breakpointIndex - 1]}) ${value}`;
    })
    .filter((v) => v)
    .join(', ');
};

export default generateImageSizeMediaQueries;
