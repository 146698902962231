import { LinkProps } from '@chakra-ui/react';
import { FC, ReactElement } from 'react';
import { Link } from '@/sharedUI';

interface SocialMediaLinkIconProps extends LinkProps {
  dataGtm?: string;
  href: string;
  icon: ReactElement;
}

const SocialMediaLinkIcon: FC<SocialMediaLinkIconProps> = ({
  dataGtm = '',
  href,
  icon,
  ...props
}) => (
  <Link
    color='typography.pure'
    data-gtm={dataGtm}
    height={6}
    href={href}
    target='_blank'
    {...props}
  >
    {icon}
  </Link>
);

export default SocialMediaLinkIcon;
