import {
  AspectRatio,
  Box,
  Button,
  Card,
  ContainerProps,
  Heading,
  Text,
  VStack,
} from '@chakra-ui/react';
import Image from 'next/image';
import { FC, useState } from 'react';
import { Modal } from '@/sharedUI';
import { IWorkerCard, extractImage, imageSizes, sliceTextByLength } from '@/sharedLib';

interface IWorkerCardProps extends IWorkerCard {
  styles?: ContainerProps;
  isOdd?: boolean;
}

const WorkerCard: FC<IWorkerCardProps> = ({
  name,
  description,
  image,
  position,
  styles,
  isOdd = true,
}) => {
  const [isOpenDescriptionModal, setIsOpenDescriptionModal] = useState(false);
  const maxTextLength = 80;
  const slicedDescription = sliceTextByLength(description || '', maxTextLength, true);

  return (
    <Card
      bg={isOdd ? 'bg.light' : 'bg.pure'}
      border='none'
      h='max'
      maxW='md'
      minH={{ base: '3xs', md: 'sm' }}
      p={0}
      variant='outline'
      w='full'
      {...styles}
    >
      {image && (
        <AspectRatio
          ratio={1}
          w='full'
        >
          <Image
            alt={`${name} master`}
            fill
            priority
            sizes='(max-width: 768px) 100vw, (max-width: 1200px) 50vw, 33vw'
            src={extractImage(image, imageSizes.M) || ''}
            style={{
              objectFit: 'cover',
            }}
          />
        </AspectRatio>
      )}
      <VStack
        flexGrow={1}
        gap='2xs'
        justifyContent='start'
        px='sm'
        py='md'
        w='full'
      >
        <Heading
          as='h3'
          color='primary.base'
          fontSize='2xl'
          fontWeight='bold'
        >
          {name}
        </Heading>
        {description && (
          <Text
            color='typography.base'
            fontWeight='medium'
            minH={12}
            textAlign='justify'
          >
            {slicedDescription}
            {description?.length > maxTextLength && (
              <Button
                alignSelf='end'
                color='secondary.base'
                variant='link'
                onClick={() => setIsOpenDescriptionModal(true)}
              >
                more
              </Button>
            )}
          </Text>
        )}
        {position && (
          <Text
            alignSelf='end'
            border='1px solid'
            borderRadius='md'
            color='secondary.base'
            fontSize='xs'
            fontWeight='semibold'
            mt='auto'
            px='2xs'
            py='4xs'
          >
            {position}
          </Text>
        )}
      </VStack>
      <Modal
        containerProps={{ pb: 'md' }}
        isOpen={isOpenDescriptionModal}
        showCloseButton={true}
        onClose={() => setIsOpenDescriptionModal(false)}
      >
        <Heading
          as='h3'
          color='primary.base'
          fontSize='2xl'
          fontWeight='bold'
          textAlign='center'
        >
          {name}
        </Heading>
        <Text mt='sm'>{description}</Text>
      </Modal>
    </Card>
  );
};

export default WorkerCard;
