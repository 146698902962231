import { TBlockData, THeaderProps } from '@/sharedLib';

const getDefaultValues = (
  {
    contactData,
    logo,
    bg,
    buttonsPosition,
    logoPosition,
    menuPosition,
    headerButtonType,
  }: TBlockData['header'],
  profileData: any,
): THeaderProps => ({
  bg: bg ?? 'bg.pure',
  buttonsPosition: buttonsPosition ?? true,
  logoPosition: logoPosition ?? true,
  menuPosition: menuPosition ?? true,
  headerButtonType: headerButtonType ?? 'phone',
  contactData: {
    ...contactData,
    phone: contactData.phone || profileData?.phone || '',
  },
  logo: logo ?? profileData?.profile?.image,
  name: profileData?.profile?.name,
});

export default getDefaultValues;
