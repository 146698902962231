const sliceTextByLength = (text: string, length: number, withDots = false) => {
  const sliceIndex = text?.slice(0, length)?.lastIndexOf(' ');
  return text?.length > length
    ? withDots
      ? text?.slice(0, sliceIndex) + ' ... '
      : text?.slice(0, sliceIndex)
    : text;
};

export default sliceTextByLength;
