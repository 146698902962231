import { ContainerProps, Flex, Heading } from '@chakra-ui/react';
import { FC, ReactNode } from 'react';

interface ColumnWithHeaderProps extends ContainerProps {
  header: string;
  children: ReactNode;
}

const ColumnWithHeader: FC<ColumnWithHeaderProps> = ({ header, children, ...props }) => (
  <Flex
    direction='column'
    gap='sm'
  >
    <Heading
      as='span'
      color='typography.pure'
      size='sm'
      textAlign={{ base: 'center', lg: 'left' }}
    >
      {header}
    </Heading>
    <Flex
      direction='column'
      gap='xs'
      justifyContent='flex-start'
      {...props}
    >
      {children}
    </Flex>
  </Flex>
);

export default ColumnWithHeader;
