import { DeleteIcon } from '@chakra-ui/icons';
import { ButtonProps, HStack, IconButton } from '@chakra-ui/react';
import { FC, ReactNode } from 'react';
import { Control, FieldValues, useFormState } from 'react-hook-form';
import { ButtonWithConfirm } from '../ButtonWithConfirm';
import Controls, { TControlsProps } from '../Controls/Controls';

type FormControlsProps<TFieldValues extends FieldValues = FieldValues> = TControlsProps & {
  control?: Control<TFieldValues>;
  children?: ReactNode;
} & (
    | {
        onDelete: () => void;
        deleteAriaLabel: string;
        confirmText?: string;
      }
    | {
        onDelete?: never;
        deleteAriaLabel?: string;
        confirmText?: string;
      }
  );

const DeleteButton: FC<ButtonProps> = (props) => (
  <IconButton
    aria-label='Delete'
    colorScheme='red'
    icon={<DeleteIcon />}
    variant='outline'
    {...props}
  />
);

const FormControls = <TFieldValues extends FieldValues = FieldValues>({
  control,
  positiveText = 'Submit',
  negativeText = 'Cancel',
  onPositiveClick = () => {},
  onNegativeClick,
  positiveVariant,
  isLoading,
  isPositiveDisabled,
  isNegativeDisabled,
  formId,
  positiveType = 'submit',
  hidePositive = false,
  containerProps = {},
  positiveButtonStyle = {},
  negativeButtonStyle = {},
  dataGtm,
  size,
  deleteAriaLabel,
  confirmText = 'Are you sure you want to delete this item?',
  children,
  onDelete,
}: FormControlsProps<TFieldValues>) => {
  const { isSubmitting, disabled } = useFormState({
    control,
  });

  const MainControls = () => (
    <Controls
      containerProps={containerProps}
      dataGtm={dataGtm}
      hidePositive={hidePositive}
      isLoading={isSubmitting || isLoading}
      isNegativeDisabled={isNegativeDisabled}
      isPositiveDisabled={isPositiveDisabled || disabled}
      negativeButtonStyle={negativeButtonStyle}
      negativeText={negativeText}
      positiveButtonStyle={{
        ...positiveButtonStyle,
        form: formId,
      }}
      positiveText={positiveText}
      positiveType={positiveType}
      positiveVariant={positiveVariant}
      size={size}
      onNegativeClick={onNegativeClick}
      onPositiveClick={onPositiveClick}
    />
  );

  if (onDelete) {
    return (
      <HStack
        mt='sm'
        w='full'
      >
        <ButtonWithConfirm
          PreviewButton={DeleteButton}
          aria-label={deleteAriaLabel}
          message={confirmText}
          onClick={onDelete}
        />
        {children}
        <MainControls />
      </HStack>
    );
  }

  if (children) {
    return (
      <HStack
        mt='sm'
        w='full'
      >
        {children}
        <MainControls />
      </HStack>
    );
  }

  return <MainControls />;
};

export default FormControls;
