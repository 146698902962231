import { ModalOverlay as ChakraModalOverlay, ModalOverlayProps } from '@chakra-ui/react';
import { FC } from 'react';

const ModalOverlay: FC<ModalOverlayProps> = (props) => (
  <ChakraModalOverlay
    backdropFilter='blur(5px)'
    bg='transparent.dark'
    {...props}
  />
);

export default ModalOverlay;
