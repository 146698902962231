import {
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink as ChakraBreadcrumbLink,
} from '@chakra-ui/react';
import { FC } from 'react';
import { BreadcrumbType } from '../../../lib';
import BreadcrumbLink from '../../BreadcrumbLink';

interface BreadcrumbsProps {
  chain: BreadcrumbType[];
}

const Breadcrumbs: FC<BreadcrumbsProps> = ({ chain }) => {
  if (chain.length < 2) return null;

  return (
    <Breadcrumb
      mb='md'
      sx={{
        ol: {
          flexWrap: 'wrap',
        },
      }}
    >
      {chain.map(({ name, route }, index) => {
        const isCurrentPage = index + 1 === chain.length;

        return (
          <BreadcrumbItem
            key={route}
            isCurrentPage
            zIndex='5'
          >
            {isCurrentPage ? (
              <ChakraBreadcrumbLink>{name}</ChakraBreadcrumbLink>
            ) : (
              <BreadcrumbLink href={route}>{name}</BreadcrumbLink>
            )}
          </BreadcrumbItem>
        );
      })}
    </Breadcrumb>
  );
};

export default Breadcrumbs;
