import { Text } from '@chakra-ui/react';
import { FC } from 'react';
import ColumnWithHeader from '../ColumnWithHeader';

const WorkingHours: FC<{ workingHours?: string }> = ({ workingHours }) => {
  const periods = workingHours?.split(/\r?\n/);

  if (!workingHours) return;

  return (
    <ColumnWithHeader header='Working Hours'>
      {periods?.map((period: string) => (
        <Text
          key={period}
          color='typography.pure'
          fontWeight='normal'
          w='max-content'
        >
          {period}
        </Text>
      ))}
    </ColumnWithHeader>
  );
};

export default WorkingHours;
