import { HStack, Stack, Text } from '@chakra-ui/react';
import { FC } from 'react';
import { PhotoSlider } from '@/sharedUI';
import { TPortfolio, imageSizes } from '@/sharedLib';

const PortfolioFullCard: FC<TPortfolio> = ({
  title,
  description,
  price,
  images = [],
  aspectRatio,
}) => {
  return (
    <>
      <Stack
        direction='row'
        flexWrap='wrap'
        spacing={0}
      >
        {price && (
          <HStack
            pr='lg'
            width='auto'
          >
            <Text
              fontWeight='semibold'
              whiteSpace='nowrap'
            >
              {`${price} $`}
            </Text>
          </HStack>
        )}
      </Stack>

      {description && (
        <Text
          fontSize='lg'
          mb='sm'
        >
          {description}
        </Text>
      )}
      <PhotoSlider
        extractImageSize={imageSizes.M}
        list={images}
        maxHeightForPreviewImage={32}
      />
    </>
  );
};

export default PortfolioFullCard;
