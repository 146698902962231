import dynamic from 'next/dynamic';
import { ComponentType, FC } from 'react';
import { configModel, headerSelector, templateSelector } from '@/entities/Config';
import { profileClientModel } from '@/entities/Profile';
import { TBlockProps, THeaderProps, THeaderTemplate } from '@/sharedLib';
import getDefaultValues from '../lib/defaultValues';

const Header01 = dynamic(() => import('./Header01'));
const Header02 = dynamic(() => import('./Header02'));

const TemplateLayouts: Record<THeaderTemplate, ComponentType<THeaderProps>> = {
  header01: Header01,
  header02: Header02,
};

const Header: FC<TBlockProps> = ({ containerStyles }) => {
  const data = configModel.useStore(headerSelector);

  const { header: headerTemplate } = configModel.useStore(templateSelector) || {};

  const { profileData } = profileClientModel.useProfile();
  const CurrentHeader = TemplateLayouts[(headerTemplate as THeaderTemplate) || 'header01'];

  const headerProps = getDefaultValues(data, profileData);

  return (
    <CurrentHeader
      containerStyles={containerStyles}
      {...headerProps}
    />
  );
};

export default Header;
