import {
  FormControl,
  FormErrorMessage,
  FormHelperText,
  Input,
  InputProps,
  StyleProps,
} from '@chakra-ui/react';
import { ChangeEventHandler, FocusEventHandler } from 'react';
import { Control, FieldPath, FieldValues, RegisterOptions, useController } from 'react-hook-form';
import { REQUIRED_FIELD_MSG } from '@/shared/lib';
import LabelForm from './LabelForm';

export type TInputFieldProps<
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>,
> = InputProps & {
  control?: Control<TFieldValues>;
  name: TName;
  validationParameters?: Omit<
    RegisterOptions<TFieldValues, TName>,
    'disabled' | 'setValueAs' | 'valueAsNumber' | 'valueAsDate'
  >;
  label?: string;
  helperText?: string;
  isErrorSpace?: boolean;
  containerStyle?: StyleProps;
};

const InputField = <
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>,
>({
  name,
  placeholder,
  value,
  control,
  isErrorSpace = false,
  isRequired,
  validationParameters,
  label,
  helperText,
  containerStyle,
  onBlur,
  onChange,
  ...style
}: TInputFieldProps<TFieldValues, TName>) => {
  const {
    fieldState: { invalid, error },
    field: { onChange: onFieldChange, onBlur: onFieldBlur, ...restFiledProps },
  } = useController({
    name,
    control,
    rules: isRequired
      ? {
          required: REQUIRED_FIELD_MSG,
          ...validationParameters,
        }
      : validationParameters,
  });

  const handleChange: ChangeEventHandler<HTMLInputElement> = (event) => {
    onFieldChange(event);
    onChange?.(event);
  };

  const handleBlur: FocusEventHandler<HTMLInputElement> = (event) => {
    onFieldBlur();
    onBlur?.(event);
  };

  const showHelperText = helperText && !error?.message;
  const reserveSpaceForError = isErrorSpace && !showHelperText && !error?.message;

  return (
    <FormControl
      isInvalid={invalid}
      isRequired={isRequired}
      {...containerStyle}
    >
      {label && <LabelForm label={label} />}
      <Input
        id={name}
        mb={reserveSpaceForError ? 'lg' : 0}
        placeholder={placeholder}
        onBlur={handleBlur}
        onChange={handleChange}
        {...restFiledProps}
        {...style}
      />
      <FormErrorMessage>{error?.message}</FormErrorMessage>
      {showHelperText && <FormHelperText> {helperText}</FormHelperText>}
    </FormControl>
  );
};

export default InputField;
