import { Box, Card, Flex, HStack, Heading, Img } from '@chakra-ui/react';
import dynamic from 'next/dynamic';
import Image from 'next/image';
import { ComponentType, FC, useEffect, useMemo, useState } from 'react';
import {
  blocksDefaultVariant,
  configModel,
  coreConfigSelector,
  reviewsSelector,
  templateSelector,
} from '@/entities/Config';
import { profileClientModel, reviewsMapper } from '@/entities/Profile';
import { Layout, Link, Rating, YelpLogo } from '@/sharedUI';
import { IReview, TBlockProps, TReviewsTemplate } from '@/sharedLib';
import exampleReviews from '../lib/reviews';
import { IReviewSliderProps, TReviewCardProps } from '../lib/types';

const ReviewCard01 = dynamic(() => import('./ReviewCard01'));
const ReviewCard02 = dynamic(() => import('./ReviewCard02'));

const SliderDots = dynamic(() => import('./SliderDots'));
const SliderArrow = dynamic(() => import('./SliderArrow'));

const CardLayouts: Record<TReviewsTemplate, ComponentType<TReviewCardProps>> = {
  reviews01: ReviewCard01,
  reviews02: ReviewCard02,
};

const SwiperLayouts = {
  dots: SliderDots,
  arrows: SliderArrow,
};

const ReviewsBlock: FC<TBlockProps> = ({ isOdd, containerStyles }) => {
  const { isEdit } = configModel.useGetStoreData(coreConfigSelector);

  const { yelpId, google, yelp } = configModel.useStore(reviewsSelector);

  const { reviews: reviewsTemplate, reviewsSlider } = configModel.useStore(templateSelector);

  const { profileData } = profileClientModel.useProfile();
  const reviewsLocalProf = profileClientModel.useFeedbacks(profileData?.profile?.id);
  const [ReviewCard, setReviewCard] = useState<ComponentType<TReviewCardProps>>(
    CardLayouts[reviewsTemplate || blocksDefaultVariant.reviews],
  );
  const [ReviewSlider, setReviewSlider] = useState<ComponentType<IReviewSliderProps>>(
    SwiperLayouts[reviewsSlider || 'dots'],
  );

  useEffect(() => {
    setReviewCard(CardLayouts[reviewsTemplate || blocksDefaultVariant.reviews]);
    setReviewSlider(SwiperLayouts[reviewsSlider || 'dots']);
  }, [reviewsTemplate, reviewsSlider]);

  const reviews = useMemo(
    () => reviewsMapper(reviewsLocalProf, google?.reviews, yelp?.reviews),
    [reviewsLocalProf, google?.reviews, yelp?.reviews],
  );

  if (!reviews && !isEdit) {
    return null;
  }

  return (
    <Layout.Container
      data-testid='reviews-block'
      isOdd={isOdd}
      pb={reviewsSlider === 'dots' ? 'md' : '3xl'}
      pt='3xl'
      withPadding={false}
      {...containerStyles}
    >
      <Heading
        id='reviews'
        textAlign='center'
        variant='homeBlock'
      >
        Reviews
      </Heading>

      {!!(google?.reviewCount || yelp?.reviewCount) && (
        <HStack
          flexWrap='wrap'
          justifyContent='center'
          p='sm'
          spacing='md'
          w='100%'
        >
          {!!google?.reviewCount && (
            <Card
              bg={isOdd ? 'bg.light' : 'bg.pure'}
              border='none'
              p='xs'
              variant='outline'
            >
              <Flex alignItems='center'>
                <Img
                  alt='google-logo'
                  h={6}
                  ml='auto'
                  src='/images/google_on_white.png'
                />
                <Rating
                  rating={google.rating || 0}
                  size={{ base: 4, md: 6 }}
                />
              </Flex>
              Based on {google?.reviewCount} reviews
            </Card>
          )}
          {!!yelp?.reviewCount && (
            <Card
              bg={isOdd ? 'bg.light' : 'bg.pure'}
              border='none'
              p='xs'
              variant='outline'
            >
              <Flex alignItems='center'>
                <Link
                  href={`https://www.yelp.com/biz/${yelpId}`}
                  target='_blank'
                >
                  <YelpLogo
                    h={6}
                    mr='3xs'
                    w='auto'
                  />
                </Link>
                <Box
                  h={{ base: 4, md: 6 }}
                  mx={1.5}
                  my={2}
                  position='relative'
                  w={{ base: 24, md: 36 }}
                >
                  <Image
                    alt={`${yelp.rating} out of 5 stars rating`}
                    fill
                    src={`/icons/yelp-rating/${yelp.rating}.png`}
                  />
                </Box>
              </Flex>
              Based on {yelp?.reviewCount} reviews
            </Card>
          )}
        </HStack>
      )}

      <ReviewSlider
        ReviewCard={ReviewCard}
        isOdd={isOdd}
        reviews={reviews || (exampleReviews as IReview[])}
        yelpId={yelpId}
      />
    </Layout.Container>
  );
};

export default ReviewsBlock;
