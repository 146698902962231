import { ColorProps, ContainerProps, HStack, LayoutProps, StackProps } from '@chakra-ui/react';
import { FC } from 'react';
import { StarFilled } from '../icons';

interface RatingProps extends StackProps {
  color?: ColorProps['color'];
  rating: 0 | 1 | 2 | 3 | 4 | 5;
  size?: LayoutProps['height'];
  styles?: ContainerProps;
}

const Rating: FC<RatingProps> = ({ color = 'primary.base', rating, size = 6, styles }) => {
  const stars = new Array(5).fill('');

  return (
    <HStack
      borderRadius='xl'
      flexWrap='nowrap'
      px={1.5}
      py={2}
      spacing='3xs'
      zIndex={1}
      {...styles}
    >
      {stars.map((_, index) =>
        index + 1 > rating ? (
          <StarFilled
            key={index}
            boxSize={size}
            fill='typography.medium'
          />
        ) : (
          <StarFilled
            key={index}
            boxSize={size}
            fill={color}
          />
        ),
      )}
    </HStack>
  );
};

export default Rating;
