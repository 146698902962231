import { Heading } from '@chakra-ui/react';
import { FC } from 'react';
import { configModel, coreConfigSelector, servicesSelector } from '@/entities/Config';
import { profileClientModel } from '@/entities/Profile';
import { CardsList, Layout } from '@/sharedUI';
import { TBlockProps, TServiceCard } from '@/sharedLib';
import { SERVICES_DEFAULT_HEADER } from '../lib/getDefaultValues';
import ServiceCard from './ServicesCard';

const ServicesBlock: FC<TBlockProps> = ({ isOdd, containerStyles }) => {
  const { isEdit } = configModel.useGetStoreData(coreConfigSelector);

  const { services: configServices, heading = SERVICES_DEFAULT_HEADER } =
    configModel.useStore(servicesSelector);

  const { profileData } = profileClientModel.useProfile();
  const { data: clientServices, loading } = profileClientModel.usePrices(profileData?.profile?.id);

  const services: TServiceCard[] = configServices || clientServices || [];

  if (!services.length && !loading && !isEdit) {
    return null;
  }

  return (
    <Layout.Container
      data-testid='workers'
      isOdd={isOdd}
      py='3xl'
      {...containerStyles}
    >
      <Heading
        mb='xl'
        textAlign='center'
        variant='homeBlock'
      >
        {heading}
      </Heading>
      <CardsList
        cards={
          services?.map((service, index) => (
            <ServiceCard
              key={index}
              isOdd={isOdd}
              {...service}
            />
          )) || []
        }
      />
    </Layout.Container>
  );
};

export default ServicesBlock;
