import { Button, ButtonProps } from '@chakra-ui/react';
import { FC, useState } from 'react';

interface IUseShowAllButton {
  itemsCount: number;
  showCardsCount: number;
}

const useShowAllButton = ({ itemsCount, showCardsCount }: IUseShowAllButton) => {
  const [isShowAllCards, setIsShowAllCards] = useState(false);
  const isShowButton = itemsCount > showCardsCount;

  const cardsToggle = () => {
    setIsShowAllCards(!isShowAllCards);
  };

  const ShowAllButton: FC<ButtonProps> = ({ ...styles }) => (
    <>
      {isShowButton && (
        <Button
          alignSelf='start'
          colorScheme='secondary'
          variant='link'
          onClick={() => {
            cardsToggle();
          }}
          {...styles}
        >
          {isShowAllCards ? 'Hide' : 'Show all'}
        </Button>
      )}
    </>
  );

  return { ShowAllButton, isShowAllCards };
};

export default useShowAllButton;
