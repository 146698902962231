import { Heading, Img, Stack } from '@chakra-ui/react';
import { FC, useState } from 'react';
import { configModel, coreConfigSelector, getPhotoGallerySelector } from '@/entities/Config';
import { DotsSwiper, Layout, SwiperSlider } from '@/sharedUI';
import { IImageFile, TBlockProps, extractImage, imageSizes } from '@/sharedLib';

const PhotoPortfolio: FC<TBlockProps> = ({ isOdd, index, containerStyles }) => {
  const [isOpen, setOpen] = useState(false);
  const [image, setImage] = useState<number | undefined>();

  const { isEdit } = configModel.useGetStoreData(coreConfigSelector);

  const {
    heading = 'Photo Gallery',
    images = [],
    isVertical,
  } = configModel.useStore(getPhotoGallerySelector(index));

  if (!images.length && !isEdit) return null;

  const openModal = (i?: number) => {
    setImage(i);
    setOpen(true);
  };

  return (
    <>
      <Layout.Container
        data-testid={`photo-block-${index}`}
        id={`photo-${index}`}
        isOdd={isOdd}
        {...containerStyles}
      >
        <Stack
          direction='column'
          flexWrap='nowrap'
          justifyContent='center'
          py='3xl'
          spacing={{ base: 'xl', md: 'xl' }}
        >
          <Heading
            alignSelf='center'
            variant='homeBlock'
          >
            {heading}
          </Heading>
          <SwiperSlider
            images={images}
            initImageIndex={image}
            isOpen={isOpen}
            setOpen={setOpen}
          />
          <DotsSwiper
            slides={images.map((image: IImageFile, i: number) => (
              <Img
                key={image.id}
                alt={`photo ${image.fileName || ''}`}
                borderRadius='xl'
                cursor='pointer'
                h={isVertical ? 96 : 48}
                maxW={72}
                mx='auto'
                objectFit='cover'
                src={extractImage(image, imageSizes.M)}
                w='100%'
                onClick={() => openModal(i)}
              />
            ))}
          />
        </Stack>
      </Layout.Container>
    </>
  );
};

export default PhotoPortfolio;
