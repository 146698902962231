import { IReview } from '@/sharedLib';

const reviewsMapper = (
  localprofReviews: IReview[] = [],
  googleReviews: IReview[] = [],
  yelpReviews: IReview[] = [],
) => {
  const reviews: IReview[] = [...localprofReviews, ...googleReviews, ...yelpReviews];

  if (reviews.length < 1) {
    return null;
  }

  return reviews.sort((a, b) => {
    const createdAtA = new Date(a.createdAt).getTime();
    const createdAtB = new Date(b.createdAt).getTime();
    return createdAtB - createdAtA;
  });
};

export default reviewsMapper;
