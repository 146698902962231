import dynamic from 'next/dynamic';
import { FC } from 'react';
import { configModel, mapSelector } from '@/entities/Config';
import { profileClientModel } from '@/entities/Profile';
import { TBlockProps, TMapTemplate } from '@/sharedLib';
import GoogleMapEmbed from './GoogleMapEmbed';

const Map01 = dynamic(() => import('./Map01'));
const Map02 = dynamic(() => import('./Map02'));

export interface IMapProps {
  template: TMapTemplate;
}

const Map: FC<TBlockProps> = ({ containerStyles }) => {
  const { profileData } = profileClientModel.useProfile();

  const { address, placeId } = configModel.useStore(mapSelector);

  const placeIdGoogle = placeId || profileData?.profile?.googlePlace?.id;

  return (
    <GoogleMapEmbed
      address={address!}
      borderRadius='none'
      containerStyles={containerStyles}
      height={300}
      placeId={placeIdGoogle}
      width='100%'
    />
  );
};

export default Map;
