import { v4 } from 'uuid';
import { TPortfolio, TPortfolioConfig } from '@/sharedLib';

export const PORTFOLIO_DEFAULT_HEADER = 'Portfolio';

export type TPortfolioItemFormFields = Required<Omit<TPortfolio, 'price'>> & {
  price: string;
};

export const defaultPortfolioItem: TPortfolioItemFormFields = {
  id: v4(),
  title: '',
  description: '',
  images: [],
  aspectRatio: 1,
  price: '',
};

export type TPortfolioFormFields = {
  heading: string;
  portfolio: TPortfolioItemFormFields[];
};

export const getDefaultValues = ({
  portfolio = [],
  heading = PORTFOLIO_DEFAULT_HEADER,
}: TPortfolioConfig): TPortfolioFormFields => ({
  portfolio: portfolio.map(({ price, aspectRatio, description, id, images, title }) => ({
    id: id || v4(),
    title: title || defaultPortfolioItem.title,
    description: description || defaultPortfolioItem.description,
    images: images || defaultPortfolioItem.images,
    aspectRatio: aspectRatio || defaultPortfolioItem.aspectRatio,
    price: price?.toString() || defaultPortfolioItem.price,
  })),
  heading,
});
