import { HStack } from '@chakra-ui/react';
import { FC } from 'react';
import { FacebookIcon, InstagramIcon, TelegramIcon, XIcon } from '@/sharedUI';
import { TSocialMediaLinksProps } from '@/sharedLib';
import SocialMediaLinkIcon from './SocialMediaLinkIcon';

const SocialMediaLinks: FC<TSocialMediaLinksProps> = ({
  styleLinkProps,
  data,
  containerStyles,
}) => {
  const { fbLink, telegramLink, instagramLink, xLink } = data;

  const styleLink = {
    _hover: {
      color: 'secondary.medium',
    },
    color: 'primary.base',
    height: 7,
    ...styleLinkProps,
  };

  return (
    <HStack
      gap={{ base: 'xs', md: 'sm' }}
      {...containerStyles}
      minW='max-content'
    >
      {fbLink && (
        <SocialMediaLinkIcon
          {...styleLink}
          href={`https://www.facebook.com/${fbLink}`}
          icon={<FacebookIcon />}
        />
      )}
      {instagramLink && (
        <SocialMediaLinkIcon
          {...styleLink}
          href={`https://www.instagram.com/${instagramLink}`}
          icon={<InstagramIcon />}
        />
      )}
      {xLink && (
        <SocialMediaLinkIcon
          {...styleLink}
          href={`https://twitter.com/${xLink}`}
          icon={<XIcon />}
        />
      )}
      {telegramLink && (
        <SocialMediaLinkIcon
          {...styleLink}
          href={`https://t.me/${telegramLink}`}
          icon={<TelegramIcon />}
        />
      )}
    </HStack>
  );
};

export default SocialMediaLinks;
