import { IReview, ReviewSourceTypeEnum } from '@/sharedLib';

const exampleReviews: IReview[] = [
  {
    author: {
      name: 'John Calvin',
      photo: '/images/man-face.png',
    },
    rating: 5,
    text: "I recently had an issue with my RV refrigerator and reached out to RV Appliance Repair. They were incredibly responsive, sending a technician out the same day. The technician was knowledgeable, courteous, and efficiently diagnosed the problem. Within hours, my refrigerator was running like new. I'm thoroughly impressed with their quick and professional service and wouldn't hesitate to recommend them to any RV owner in need of reliable repairs.",
    createdAt: 1699919900000,
    jobType: 'RV Appliance Repair',
    sourceType: ReviewSourceTypeEnum.GOOGLE,
  },
  {
    author: {
      name: 'Tom Anderson',
      photo: '/images/man-face2.jpg',
    },
    rating: 4,
    text: "I'm absolutely thrilled with the interior remodel of my RV done by the team. The new layout maximizes space and the modern finishes have truly transformed the look and feel of our home-on-wheels. The quality of workmanship is evident in every detail, from the custom cabinetry to the sleek flooring. The project was completed on time and within budget, which is a testament to their professionalism and expertise. I couldn't be happier with the result and the fresh, updated interior has made our RV travels even more enjoyable.",
    createdAt: 1673317200000,
    jobType: 'RV Interior Remodel',
    sourceType: ReviewSourceTypeEnum.LOCAL_PROF,
    images: [
      {
        url: '/images/review-example-1.png',
        fileName: 'RV interior example',
      },
      {
        url: '/images/review-example-2.png',
        fileName: 'RV interior example2',
      },
    ],
  },
  {
    author: {
      name: 'Mary Steager',
      photo: '/images/woman-face2.jpg',
    },
    rating: 5,
    text: "I had the pleasure of using the RV Carpet Cleaning service after a long summer of traveling. The team was prompt, arriving right on schedule with all the necessary equipment. They tackled every stain and speck of dirt with great care, leaving my RV's carpet spotless and smelling fresh. The transformation was remarkable, and the quick drying time meant I could get back to enjoying my RV without delay. Their service exceeded my expectations, and I'm grateful for their hard work.",
    createdAt: 1627327200000,
    jobType: 'RV Carpet Cleaning',
    sourceType: ReviewSourceTypeEnum.LOCAL_PROF,
  },
  {
    author: {
      name: 'Ann Talkin',
      photo: '/images/woman-face.jpg',
    },
    rating: 4,
    text: 'I recently used the RV Roof Maintenance service for my camper, and I must say, their attention to detail was exceptional. The team performed a comprehensive inspection and maintenance, ensuring every nook and cranny was sealed and secure. Their professionalism and expertise in handling RV roofs specifically gave me peace of mind for our upcoming road trips. The service was not only top-notch but also cost-effective. I highly recommend them to any RV owner looking to protect their investment with proper roof care.',
    createdAt: 1612312300000,
    jobType: 'RV Roof Maintenance',
    sourceType: ReviewSourceTypeEnum.GOOGLE,
  },
  {
    author: {
      name: 'Joanna Picker',
      photo: '/images/woman-face2.jpg',
    },
    rating: 5,
    text: 'Cool service with fast and quality result! All issues were successfully solved. Strongly recommended!',
    createdAt: 1629427200000,
    jobType: 'RV Electrical Repair',
    sourceType: ReviewSourceTypeEnum.YELP,
  },
  {
    author: {
      name: 'Andrew Strong',
      photo: '/images/man-face2.jpg',
    },
    rating: 5,
    text: 'The technician was incredibly knowledgeable and took the time to explain everything in detail',
    createdAt: 1698727700000,
    jobType: 'RV Engine Service',
    sourceType: ReviewSourceTypeEnum.GOOGLE,
  },
];

export default exampleReviews;
