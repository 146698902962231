import { Box, BoxProps } from '@chakra-ui/react';
import React, { FC } from 'react';
import { config } from '@/sharedLib';
import { TMapProps } from '../types';

type GoogleMapEmbedProps = {
  width?: number | string;
  height?: number | string;
  borderRadius?: string;
  containerStyles?: BoxProps;
} & Pick<TMapProps, 'address' | 'email' | 'phone' | 'placeId' | 'mapType'>;

const GoogleMapEmbed: FC<GoogleMapEmbedProps> = ({
  address,
  placeId,
  borderRadius = 'md',
  width = '100%',
  height = 450,
  mapType = 'roadmap',
  containerStyles,
}) => {
  const params = new URLSearchParams({
    key: config.googleMapsApiKey,
    q: placeId ? `place_id:${placeId}` : address,
    maptype: mapType,
  }).toString();

  const src = `https://www.google.com/maps/embed/v1/place?${params}`;

  return (
    <Box
      borderRadius={borderRadius}
      data-testid='map-block'
      id='map'
      overflow='hidden'
      w='full'
      {...containerStyles}
    >
      <iframe
        allowFullScreen
        height={height}
        loading='lazy'
        referrerPolicy='no-referrer-when-downgrade'
        src={src}
        style={{ border: 0 }}
        width={width}
      />
    </Box>
  );
};

export default GoogleMapEmbed;
