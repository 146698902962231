import { AspectRatio, Box, ContainerProps, Heading, Stack, Text, VStack } from '@chakra-ui/react';
import Image from 'next/image';
import { FC } from 'react';
import { configModel, coreConfigSelector, getInfoSelector } from '@/entities/Config';
import { Layout } from '@/sharedUI';
import { TBlockData, TBlockProps, extractImage, imageSizes } from '@/sharedLib';

const defaultImage = '/images/default-snippet.png';
const defaultHeading = 'Info block';

export interface IImageCardProps extends ContainerProps {
  imageRatio?: any;
  image?: any;
}

const ImageCard: FC<IImageCardProps> = ({ imageRatio, image, ...styles }) => (
  <AspectRatio
    maxW='full'
    minWidth={{ base: '80vw', md: '40vw', lg: 'md' }}
    ratio={imageRatio || '1'}
    {...styles}
  >
    <Box borderRadius='xl'>
      <Image
        alt='About us'
        fill
        priority
        sizes='(max-width: 768px) 100vw, 50vw'
        src={extractImage(image, imageSizes.M) || defaultImage}
        style={{
          objectFit: 'cover',
        }}
      />
    </Box>
  </AspectRatio>
);

const InfoBlock: FC<TBlockProps> = ({ isOdd, isPreview, index, containerStyles }) => {
  const { isEdit } = configModel.useGetStoreData(coreConfigSelector);

  const data = configModel.useStore(getInfoSelector(index)) || {};

  const {
    heading,
    description,
    headingSize,
    descriptionSize,
    showImage,
    image,
    imagePosition,
    imageRatio,
  } = data;

  if (!heading && !description && !image && !isEdit) {
    return null;
  }

  return (
    <Layout.Container
      data-testid={`info-block-${index}`}
      id={`info-${index}`}
      isOdd={isOdd}
      py={isPreview ? 'xs' : '4xl'}
      {...containerStyles}
    >
      <Stack
        alignItems='center'
        flexDir={{ base: 'column', md: imagePosition === 'left' ? 'row-reverse' : 'row' }}
        gap={{ base: 'md', md: '5xl' }}
        justifyContent='space-between'
      >
        <VStack w='full'>
          <Heading
            color='primary.base'
            fontSize={headingSize || 'xl'}
            textAlign='center'
            textTransform='uppercase'
          >
            {heading || defaultHeading}
          </Heading>

          {showImage && (
            <ImageCard
              display={{ base: 'flex', md: 'none' }}
              image={image}
              imageRatio={imageRatio}
              my='lg'
            />
          )}

          {description && (
            <Text
              color='typography.base'
              fontSize={descriptionSize || 'lg'}
              pt={{ base: 'none', md: 'lg' }}
              textAlign='justify'
              whiteSpace='pre-line'
            >
              {description || ''}
            </Text>
          )}
        </VStack>
        {showImage && (
          <ImageCard
            display={{ base: 'none', md: 'flex' }}
            image={image}
            imageRatio={imageRatio}
          />
        )}
      </Stack>
    </Layout.Container>
  );
};

export default InfoBlock;
