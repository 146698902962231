import { Box } from '@chakra-ui/react';
import { FC, useId } from 'react';
import { useForm } from 'react-hook-form';
import { privateConfigModel } from '@/entities/PrivateConfig';
import { Form, FormControls, InputField, TextareaField } from '@/sharedUI';
import { REQUIRED_FIELD_MSG, TBlockProps, emailRegExp, phoneRegExp } from '@/sharedLib';

type ContactUsFormProps = TBlockProps & {
  headingFormText?: string;
};

const ContactUsForm: FC<ContactUsFormProps> = ({ isOdd = true, containerStyles }) => {
  const formId = useId();
  const methods = useForm({
    mode: 'onSubmit',
    reValidateMode: 'onChange',
    defaultValues: {
      name: '',
      email: '',
      phone: '',
      message: '',
    },
  });

  const { sendMessage, loading } = privateConfigModel.useSendContactFormMessage();

  const onSubmit = (data: any) =>
    sendMessage({
      email: data?.email,
      phone: data?.phone,
      name: data?.name,
      description: data?.message,
    });

  return (
    <Box
      bg={isOdd ? 'bg.light' : 'bg.pure'}
      position='relative'
      {...containerStyles}
    >
      <Form
        formId={formId}
        methods={methods}
        onSubmit={onSubmit}
      >
        <InputField
          bg='bg.pure'
          isErrorSpace
          name='name'
          validationParameters={{
            required: REQUIRED_FIELD_MSG,
          }}
          placeholder='Name'
        />
        <InputField
          bg='bg.pure'
          isErrorSpace
          name='email'
          placeholder='E-mail'
          validationParameters={{
            required: REQUIRED_FIELD_MSG,
            pattern: {
              value: emailRegExp,
              message: 'Wrong email address',
            },
          }}
        />
        <InputField
          bg='bg.pure'
          isErrorSpace
          name='phone'
          placeholder='Phone'
          validationParameters={{
            required: REQUIRED_FIELD_MSG,
            pattern: {
              value: new RegExp(`${phoneRegExp}`),
              message: 'Please enter a valid number',
            },
          }}
        />
        <TextareaField
          bg='bg.pure'
          isErrorSpace
          name='message'
          placeholder='Message'
          validationParameters={{
            required: REQUIRED_FIELD_MSG,
          }}
        />

        <FormControls
          formId={formId}
          isLoading={loading}
          positiveButtonStyle={{ width: '100%' }}
          positiveText='Send'
        />
      </Form>
    </Box>
  );
};

export default ContactUsForm;
