import { SVGAttributes } from 'react';

const MailIcon = (props: SVGAttributes<never>) => (
  <svg
    fill='none'
    height='100%'
    viewBox='0 0 20 20'
    width='100%'
    xmlns='http://www.w3.org/2000/svg'
    {...props}
  >
    <g clipPath='url(#clip0_2152_2355)'>
      <path
        d='M9.26167 12.1299C9.45724 12.3249 9.72215 12.4344 9.99833 12.4344C10.2745 12.4344 10.5394 12.3249 10.735 12.1299L18.8067 4.05825C18.8615 4.00365 18.9 3.93487 18.9179 3.85962C18.9358 3.78436 18.9324 3.70561 18.9081 3.63217C18.8838 3.55872 18.8396 3.4935 18.7803 3.4438C18.721 3.3941 18.6491 3.36188 18.5725 3.35075C18.4933 3.33909 18.4134 3.33324 18.3333 3.33325H1.66667C1.58607 3.33315 1.50557 3.339 1.42583 3.35075C1.34928 3.36188 1.27733 3.3941 1.21805 3.4438C1.15877 3.4935 1.1145 3.55872 1.09019 3.63217C1.06589 3.70561 1.0625 3.78436 1.08043 3.85962C1.09835 3.93487 1.13687 4.00365 1.19167 4.05825L9.26167 12.1299Z'
        fill='currentColor'
      />
      <path
        d='M19.9067 4.85992C19.8788 4.84809 19.8481 4.84487 19.8184 4.85066C19.7887 4.85645 19.7614 4.87099 19.74 4.89242L14.5733 10.0591C14.5344 10.0983 14.5125 10.1513 14.5125 10.2066C14.5125 10.2619 14.5344 10.3149 14.5733 10.3541L18.7758 14.5566C18.8356 14.6142 18.8832 14.6832 18.916 14.7594C18.9488 14.8356 18.9661 14.9176 18.9668 15.0006C18.9676 15.0836 18.9518 15.1659 18.9204 15.2427C18.889 15.3196 18.8427 15.3894 18.784 15.4481C18.7253 15.5068 18.6556 15.5532 18.5788 15.5847C18.502 15.6162 18.4197 15.632 18.3367 15.6313C18.2537 15.6306 18.1717 15.6134 18.0954 15.5807C18.0192 15.548 17.9502 15.5004 17.8925 15.4408L13.69 11.2383C13.6706 11.2189 13.6477 11.2035 13.6223 11.193C13.597 11.1825 13.5699 11.177 13.5425 11.177C13.5151 11.177 13.488 11.1825 13.4627 11.193C13.4373 11.2035 13.4144 11.2189 13.395 11.2383L11.6208 13.0124C11.1911 13.4419 10.6084 13.6832 10.0008 13.6832C9.39327 13.6832 8.81057 13.4419 8.38083 13.0124L6.605 11.2399C6.56581 11.2009 6.51278 11.1791 6.4575 11.1791C6.40222 11.1791 6.34919 11.2009 6.31 11.2399L2.1075 15.4424C1.98957 15.5562 1.83166 15.6191 1.66779 15.6176C1.50392 15.6161 1.34719 15.5503 1.23136 15.4344C1.11554 15.3185 1.04988 15.1617 1.04854 14.9978C1.04719 14.8339 1.11026 14.6761 1.22417 14.5583L5.42667 10.3558C5.46565 10.3166 5.48753 10.2635 5.48753 10.2083C5.48753 10.153 5.46565 10.0999 5.42667 10.0608L0.26 4.89409C0.238757 4.87246 0.21146 4.85778 0.181708 4.85198C0.151956 4.84618 0.121145 4.84953 0.0933333 4.86159C0.0659541 4.87299 0.0425201 4.89218 0.0259312 4.91676C0.00934231 4.94135 0.000326684 4.97026 0 4.99992V14.9999C0 15.4419 0.175595 15.8659 0.488155 16.1784C0.800716 16.491 1.22464 16.6666 1.66667 16.6666H18.3333C18.7754 16.6666 19.1993 16.491 19.5118 16.1784C19.8244 15.8659 20 15.4419 20 14.9999V4.99992C20.0001 4.96995 19.9913 4.94063 19.9747 4.9157C19.958 4.89076 19.9344 4.87134 19.9067 4.85992Z'
        fill='currentColor'
      />
    </g>
    <defs>
      <clipPath id='clip0_2152_2355'>
        <rect
          fill='currentColor'
          height='20'
          width='20'
        />
      </clipPath>
    </defs>
  </svg>
);

export default MailIcon;
