import { Heading } from '@chakra-ui/react';
import { FC } from 'react';
import { configModel, coreConfigSelector, portfolioSelector } from '@/entities/Config';
import { CardsList, Layout } from '@/sharedUI';
import { TBlockProps } from '@/sharedLib';
import { PORTFOLIO_DEFAULT_HEADER } from '../lib/getDefaultValues';
import PortfolioShortCard from './PortfolioCard';

export const stylesEditablePortfolioShortCard = (isSignup?: boolean) =>
  isSignup
    ? {
        width: {
          base: '100%',
          sm: 'calc((100% - 24px) / 2)',
          md: 'calc((100vw - 40px - (48px * 2) - (40px * 2) - 4 * 25px) / 3)',
          lg: 'calc((100vw - 40px - (48px * 2) - (48px * 2) - 5 * 25px) / 4)',
          xl: 'calc((1100px - (48px * 2) - (48px * 2) - 5 * 25px) / 4)',
        },
      }
    : {
        width: {
          base: '100%',
          sm: 'calc((100% - 24px) / 2)',
          md: 'calc((100vw - 40px - 80px - 2 * 24px) / 3 - 16px)',
          lg: 'calc((100vw - 40px - 80px - 3 * 24px) / 4 - 12px)',
          xl: 'calc((1100px - 80px - 3 * 24px) / 4 - 12px)',
        },
      };

const PortfolioBlock: FC<TBlockProps> = ({ isOdd, containerStyles }) => {
  const { isEdit } = configModel.useGetStoreData(coreConfigSelector);

  const { heading = PORTFOLIO_DEFAULT_HEADER, portfolio } = configModel.useStore(portfolioSelector);

  if (!portfolio?.length && !isEdit) {
    return null;
  }

  return (
    <Layout.Container
      data-testid='portfolio-block'
      isOdd={isOdd}
      py='3xl'
      {...containerStyles}
    >
      <Heading
        mb='xl'
        textAlign='center'
        variant='homeBlock'
      >
        {heading}
      </Heading>

      <CardsList
        cards={
          portfolio?.map((portfolioItem) => (
            <PortfolioShortCard
              key={portfolioItem.id}
              {...portfolioItem}
              isOdd={isOdd}
            />
          )) || []
        }
      />
    </Layout.Container>
  );
};

export default PortfolioBlock;
