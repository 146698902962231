import { convertBytesToMb, parsAcceptedFileExtension } from '../helpers';
import { TAccept } from './validFileExtension';

export const REQUIRED_FIELD_MSG = 'Required field';
export const EMAIL_VALIDATION_MSG = 'Invalid email';
export const PHONE_VALIDATION_MSG = 'Invalid phone number';
export const NAME_VALIDATION_MSG = 'Invalid name';
export const INVALID_CHARACTERS_MSG = 'Invalid characters';
export const PASSWORD_VALIDATION_MSG =
  'Should contain at least 8 characters, one uppercase letter, one lowercase letter and one number';
export const AT_LEAST_ONE_UPPERCASE_LETTER_MSG = 'Should contain at least one uppercase letter';
export const AT_LEAST_ONE_LOWERCASE_LETTER_MSG = 'Should contain at least one lowercase letter';
export const AT_LEAST_ONE_NUMBER_MSG = 'Should contain at least one number';
export const ONLY_LETTERS_AND_NUMBERS_MSG = 'Should contain only letters and numbers';
export const EMAIL_ALREADY_EXISTS_MSG = 'Email already exists';
export const getMinLengthValidationMsg = (minLength: number) =>
  `Should contain at least ${minLength} characters`;
export const getMaxLengthValidationMsg = (maxLength: number) =>
  `Should contain less then ${maxLength} letters`;
export const getMinValueValidationMsg = (min: number) => `Should be more than ${min}`;
export const getMaxValueValidationMsg = (max: number | string) => `Should be less than ${max}`;
export const getFollowingLengthValidationMsg = (length: number) =>
  `Should contain ${length} numbers`;
export const INVALID_FILE_SIZE = 'Invalid file size!';
export const UPLOAD_FAILED = 'Upload failed!';
export const getFileTooBigMessage = (maxFileSizeInBytes?: number) => {
  const message = 'File size is too big.';

  if (!maxFileSizeInBytes) return message;

  return `${message} Max size is ${convertBytesToMb(maxFileSizeInBytes)} Mb`;
};
export const getInvalidTypeMessage = (acceptedFileExtension: TAccept[]) => {
  const message = 'Invalid file type.';

  if (!acceptedFileExtension) return message;

  const acceptedExtensions = parsAcceptedFileExtension(acceptedFileExtension);

  return `${message} Accepted types: ${acceptedExtensions}`;
};
export const VALIDATION_FAILED = 'Validation failed!';
