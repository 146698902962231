import { FC } from 'react';
import { configModel, contactsSelector } from '@/entities/Config';
import { profileClientModel } from '@/entities/Profile';
import { ContactLinkItem, MailIcon, MapPinIcon, PhoneIcon } from '@/sharedUI';
import { phoneFormat } from '@/sharedLib';
import ColumnWithHeader from '../ColumnWithHeader';

const Contacts: FC = () => {
  const { phone: tel, email, address_url, address } = configModel.useStore(contactsSelector);
  const { profileData } = profileClientModel.useProfile();
  const phone = tel || profileData?.phone;

  if (!phone && !email && !address_url && !address) return;

  return (
    <ColumnWithHeader
      header='Contacts'
      maxW={{ base: 'xs', sm: 'sm', md: 'xs' }}
      w='max-content'
    >
      {phone && (
        <ContactLinkItem
          color='typography.pure'
          dataGtm='phone-footer'
          href={`tel:${phone}`}
          icon={<PhoneIcon />}
          label={phoneFormat(phone)}
        />
      )}
      {email && (
        <ContactLinkItem
          color='typography.pure'
          dataGtm='mail-footer'
          href={`mailto:${email}`}
          icon={<MailIcon />}
          label={email}
        />
      )}
      {address && address_url && (
        <ContactLinkItem
          color='typography.pure'
          dataGtm='location-footer'
          href={address_url}
          icon={<MapPinIcon />}
          label={address}
          targetBlank
        />
      )}
    </ColumnWithHeader>
  );
};

export default Contacts;
