import { IOurWorkersConfig, IWorkerCard } from '@/sharedLib';

export const OUR_WORKERS_DEFAULT_HEADER = 'Our Team';

export type TOurWorkersFormFields = {
  heading: string;
  workers: Required<IWorkerCard>[];
};

export const getDefaultValues = ({
  heading = OUR_WORKERS_DEFAULT_HEADER,
  workers = [],
}: IOurWorkersConfig): TOurWorkersFormFields => ({
  heading,
  workers: workers.map((worker) => ({
    ...defaultWorker,
    ...worker,
  })),
});

export const defaultWorker: Required<IWorkerCard> = {
  name: '',
  description: '',
  image: null,
  position: '',
};
