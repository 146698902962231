import { Icon, IconProps } from '@chakra-ui/react';

const StarIcon = (props: IconProps) => (
  <Icon
    fill='none'
    height='100%'
    viewBox='0 0 32 33'
    width='100%'
    xmlns='http://www.w3.org/2000/svg'
    {...props}
  >
    <path d='M16.0158 6.33594L13.0658 12.3141L6.46582 13.2734L11.2408 17.9297L10.1127 24.5016L16.0158 21.3984L21.9189 24.4984L20.7908 17.9266L25.5658 13.2734L18.9658 12.3141L16.0158 6.33594Z' />
    <path d='M28.3941 11.4117L20.4597 10.2586L16.9128 3.06798C16.8159 2.87111 16.6566 2.71173 16.4597 2.61486C15.9659 2.37111 15.3659 2.57423 15.1191 3.06798L11.5722 10.2586L3.6378 11.4117C3.41905 11.443 3.21905 11.5461 3.06593 11.7024C2.88081 11.8926 2.7788 12.1486 2.78231 12.414C2.78583 12.6795 2.89458 12.9327 3.08468 13.118L8.8253 18.7149L7.46905 26.618C7.43725 26.8018 7.45759 26.9909 7.52778 27.1638C7.59796 27.3366 7.71518 27.4864 7.86614 27.596C8.0171 27.7057 8.19576 27.7708 8.38186 27.7841C8.56797 27.7974 8.75406 27.7582 8.91905 27.6711L16.0159 23.9399L23.1128 27.6711C23.3066 27.7742 23.5316 27.8086 23.7472 27.7711C24.2909 27.6774 24.6566 27.1617 24.5628 26.618L23.2066 18.7149L28.9472 13.118C29.1034 12.9649 29.2066 12.7649 29.2378 12.5461C29.3222 11.9992 28.9409 11.493 28.3941 11.4117V11.4117ZM20.7909 17.9274L21.9191 24.4992L16.0159 21.3992L10.1128 24.5024L11.2409 17.9305L6.46593 13.2742L13.0659 12.3149L16.0159 6.33673L18.9659 12.3149L25.5659 13.2742L20.7909 17.9274V17.9274Z' />
  </Icon>
);

export default StarIcon;
