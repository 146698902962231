import { Box, BoxProps, useConst } from '@chakra-ui/react';
import NextImage from 'next/image';
import { FC } from 'react';
import { extractImage, generateImageSizeMediaQueries, imageSizes } from '../lib';
import { IImageFile, ResponsiveValue } from '../lib/types';

export type ImageProps = Omit<BoxProps, 'src'> & {
  imgSizes?: ResponsiveValue | string;
  alt?: string;
  objectFit?: 'fill' | 'contain' | 'cover' | 'none' | 'scale-down';
  priority?: boolean;
  isEager?: boolean;
  unoptimized?: boolean;
  imageSize?: string;
} & (
    | {
        image: IImageFile;
        src?: never;
      }
    | {
        image?: never;
        src: string;
      }
  );

const Image: FC<ImageProps> = ({
  image,
  src,
  imgSizes,
  alt = '',
  objectFit = 'cover',
  priority,
  isEager,
  unoptimized,
  imageSize = imageSizes.S_ORIGINAL_ASPECT_RATIO,
  ...boxProps
}) => {
  const sizes = useConst(() => (imgSizes ? generateImageSizeMediaQueries(imgSizes) : undefined));

  return (
    <Box
      height='full'
      overflow='hidden'
      position='relative'
      {...boxProps}
    >
      <NextImage
        alt={alt}
        fill
        loading={isEager ? 'eager' : 'lazy'}
        priority={priority}
        sizes={sizes}
        src={image ? extractImage(image, imageSize) : src}
        style={{ objectFit }}
        unoptimized={unoptimized}
      />
    </Box>
  );
};

export default Image;
