import { ViewIcon } from '@chakra-ui/icons';
import { Flex, Img, Text } from '@chakra-ui/react';
import { useRouter } from 'next/router';
import { FC, useState } from 'react';
import { extractImage, imageSizes } from '@/sharedLib';
import SwiperSlider from '../Swiper/SwiperSlider';

interface PhotoSliderProps {
  list: any[];
  previewSize?: number[];
  isHide?: boolean;
  showPhotoCount?: number;
  userId?: string;
  isExtractImage?: boolean;
  isSystemUser?: boolean;
  maxHeightForPreviewImage?: string | number | string[] | number[];
  isVisible?: boolean;
  extractImageSize?: string;
  [key: string]: any;
}

const PhotoSlider: FC<PhotoSliderProps> = ({
  list,
  previewSize = [25, 25, 52],
  isHide,
  showPhotoCount = 1,
  userId,
  isExtractImage = true,
  isSystemUser = false,
  maxHeightForPreviewImage,
  isVisible = true,
  extractImageSize = imageSizes.S,
  ...other
}) => {
  const [isOpen, setOpen] = useState(false);
  const [image, setImage] = useState<number | undefined>();
  const router = useRouter();

  const openModal = (i?: number) => {
    setImage(i);
    setOpen(true);
  };

  const isLastItem = (i: number, array: any) => i === array.length - 1;

  const imageHandler = (i: number, imageId: string) => {
    if (isSystemUser) {
      navigator.clipboard.writeText(imageId);
    }
    openModal(i);
  };

  const getItemSrc = (e: any) => (isExtractImage ? extractImage(e, extractImageSize) : e?.url);

  const getLastItemSrc = () =>
    isExtractImage ? extractImage(list[list.length - 1], extractImageSize) : list?.[0]?.url;

  return (
    <Flex
      overflowX='auto'
      {...other}
    >
      <SwiperSlider
        images={list}
        initImageIndex={image}
        isOpen={isOpen}
        setOpen={setOpen}
      />
      {list?.map((item: any, i: number) =>
        isLastItem(i, list) && isHide && i === showPhotoCount - 1 ? (
          <Flex
            key={item.id}
            alignItems='center'
            boxSize={previewSize}
            color='typography.pure'
            cursor='pointer'
            justifyContent='center'
            pos='relative'
            onClick={() => router.push(`/executors/${userId}`)}
          >
            <Img
              borderRadius='lg'
              h={isExtractImage ? previewSize : 'auto'}
              pos='absolute'
              src={isVisible ? getLastItemSrc() : null}
            />
            <Flex
              bg='typography.primary'
              borderRadius='lg'
              boxSize='100%'
              opacity='0.5'
              pos='absolute'
            />
            <Flex
              alignItems='center'
              flexDir='column'
              minW={previewSize}
              zIndex={1}
            >
              <ViewIcon w={5} />
              <Text
                fontSize='xs'
                fontWeight={'bold'}
              >
                All-photos
              </Text>
            </Flex>
          </Flex>
        ) : (
          <Img
            key={item.id}
            borderRadius='lg'
            cursor='pointer'
            data-testid='photo'
            h={isExtractImage ? previewSize : 'auto'}
            maxH={maxHeightForPreviewImage || 'auto'}
            mr={list?.length > 1 ? 'xs' : 0}
            src={isVisible ? getItemSrc(item) : null}
            onClick={() => imageHandler(i, item.id)}
          />
        ),
      )}
    </Flex>
  );
};

export default PhotoSlider;
